@media screen and (max-width: 768px) {
    #title {
        display: none;
    }

    #fullscreen {
        display: none;
    }
}

// @media screen and (max-width: 768px) and (orientation: portrait) {
//     html {
//         transform: rotate(-90deg);
//         transform-origin: left top;
//         height: 100vw;
//         width: 100vh;
//         overflow-x: hidden;
//         position: absolute;
//         top: 100%;
//         left: 0;
//     }

//     .container {
//         height: 90vw;
//     }

//     .url-container {
//         width: 50vh;
//     }

//     .video-container {
//         width: 100vw;
//     }
// }
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

.brainchild-watermark {
    display: block;
    position: absolute;
    top: 6rem;
    right: 7rem;
    opacity: 0.5;

    width: 200px;
    max-width: 20vw;
    height: 200px;

    background-image: url("../public/image/watermark.png");
    background-size: 100%;
    background-repeat: no-repeat;

    @media screen and (orientation: portrait) {
        @media screen and (max-width: 550px) {
            right: 2rem;
        }

        @media screen and (min-width: 551px) and (max-width: 1024) {
            right: 3rem;
        }
    }

    @media screen and (orientation: landscape) {
        @media screen and (max-width: 550px) {
            right: 2rem;
        }

        @media screen and (min-width: 551px) and (max-width: 768px) {
            right: 3rem;
        }

        @media screen and (min-width: 769px) and (max-width: 1024px) {
            right: 4rem;
        }
    }

    @include transition(visibility 1s, opacity 1s);
}
